<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Disclaimer voor https://hostingondemand.nl</h1>
        <p>Deze algemene voorwaarden zijn van toepassing op iedere aanbieding of offerte van Hosting on Demand ter zake van Diensten en maken integraal onderdeel uit van iedere Overeenkomst tussen Hosting on Demand en Klant. Bepalingen of voorwaarden gesteld door Klant die afwijken van, of niet voorkomen in, deze Algemene Voorwaarden zijn voor Hosting on Demand alleen bindend indien en voor zover deze door Hosting on Demand uitdrukkelijk Schriftelijk zijn aanvaard.</p>
        <p>Met een hoofdletter geschreven termen hebben de betekenis zoals in het laatste artikel vermeld.</p>
        <h2>Artikel 1. Contractsluiting</h2>
        <p>&nbsp;</p>
        <p>1.1  Klant kan de Diensten direct vanaf de Hosting on Demand Website aanvragen. De Overeenkomst ontstaat op het moment van verzending van de (al dan niet automatisch gegenereerde) e-mail van Hosting on Demand met daarin de bevestiging en acceptatie van de aanvraag. Klant kan tevens vrijblijvend een offerte aanvragen. De Overeenkomst ontstaat op het moment van ontvangst van akkoord op de offerte, mits dit plaatsvindt voor de in de offerte genoemde einddatum.</p>
        <p>&nbsp;</p>
        <p>1.2  Gesloten Overeenkomsten zijn definitief. Indien Klant een consument is, wordt de levering van Diensten met het uitdrukkelijke goedvinden van Klant direct na bestelling aangevangen.</p>
        <h2>Artikel 2. Uitvoering van de overeenkomst</h2>
        <p>&nbsp;</p>
        <p>2.1  Na de totstandkoming van de Overeenkomst zal Hosting on Demand deze naar beste kunnen en onder toepassing van voldoende zorgvuldigheid en vakmanschap nakomen.</p>
        <p>&nbsp;</p>
        <p>2.2  Hosting on Demand zal zich inspannen om kwalitatief goede en ononderbroken beschikbaarheid van Diensten en daarbij behorende systemen en netwerken te realiseren, en om toegang tot door Klant hiermee opgeslagen gegevens te realiseren. Hosting on Demand biedt echter geen garanties over kwaliteit of beschikbaarheid, tenzij anders is overeengekomen in de offerte middels een als zodanig aangeduide Service Level Agreement (SLA)</p>
        <p>&nbsp;</p>
        <p>2.3  Door Hosting on Demand opgegeven termijnen van levering hebben steeds een indicatieve strekking, behalve waar de toepasselijke SLA termijnen stelt die niet anders dan als resultaatsverbintenis kunnen worden opgevat.</p>
        <p>&nbsp;</p>
        <p>2.4  Indien en voor zover een goede uitvoering van de Overeenkomst dit vereist, heeft Hosting on Demand het recht bepaalde werkzaamheden te laten verrichten door derden. Eventuele hiermee verband houdende onverwachte meerkosten zijn voor rekening van Klant, tenzij anders overeengekomen. Deze Algemene Voorwaarden zijn tevens van toepassing op de werkzaamheden die derden in het kader van de Overeenkomst verrichten.</p>
        <p>&nbsp;</p>
        <p>2.5  Indien zulks is overeengekomen, zal Hosting on Demand Klant toegang tot een Account verschaffen. Het Account zal toegankelijk zijn door een wachtwoord en gebruikersnaam in te voeren. Iedere actie die middels het Account van Klant of een door Klant aangemaakt Account plaatsvindt, wordt geacht onder verantwoordelijkheid en risico van Klant te zijn geschied. Indien Klant vermoedt of redelijkerwijs behoort te vermoeden of te weten dat misbruik van een Account plaatsvindt, dient Klant dit zo spoedig mogelijk aan Hosting on Demand te melden zodat deze maatregelen kan nemen.</p>
        <p>&nbsp;</p>
        <p>2.6  Hosting on Demand zal zich beschikbaar houden voor een redelijk niveau van klantenondersteuning op afstand per telefoon en e-mail, tijdens reguliere kantooruren, voor zover de toepasselijke SLA niet anders bepaalt.</p>
        <p>&nbsp;</p>
        <p>2.7  Alle wijzigingen in de Overeenkomst, hetzij op verzoek van Opdrachtgever, hetzij als gevolg van het feit dat door welke omstandigheden dan ook een andere uitvoering noodzakelijk is, worden wanneer daaraan extra kosten verbonden zijn als meerwerk beschouwd en voor zover daaruit minder kosten bestaan als minderwerk. Deze worden overeenkomstig gefactureerd aan Opdrachtgever.</p>
        <h2>Artikel 3. Verplichtingen van Klant</h2>
        <p>3.1 Klant is gehouden al datgene te doen en laten wat redelijkerwijs nodig en wenselijk is om een tijdige en juiste uitvoering van de Overeenkomst mogelijk te maken. In het bijzonder draagt Klant er zorg voor dat alle gegevens waarvan Hosting on Demand aangeeft dat deze noodzakelijk zijn of waarvan de Klant redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de Diensten, tijdig aan Hosting on Demand worden verstrekt. De termijn waarbinnen Hosting on</p>
        <p>Demand de Overeenkomst dient uit te voeren, vangt niet eerder aan dan nadat alle gevraagde en benodigde gegevens door Hosting on Demand zijn ontvangen.</p>
        <p>&nbsp;</p>
        <p>3.2  Indien Klant weet of kan vermoeden dat Hosting on Demand bepaalde (extra) maatregelen zal moeten treffen om aan diens verplichtingen te kunnen voldoen, zal Klant Hosting on Demand daarvan onverwijld op de hoogte stellen. Deze verplichting geldt bijvoorbeeld als Klant weet of behoort te voorzien dat er een buitengewone piek in belasting van de systemen van Hosting on Demand zal ontstaan, die in enige waarschijnlijkheid onbeschikbaarheid van de Diensten zou kunnen veroorzaken. Dit geldt temeer indien Klant weet dat er ook Diensten aan anderen worden geleverd via dezelfde systemen als die Hosting on Demand gebruikt om Diensten aan Klant te leveren. Hosting on Demand zal er na waarschuwing alles aan doen om onbeschikbaarheid van de Diensten te voorkomen. Tenzij Schriftelijk uitdrukkelijk anders is overeengekomen, kunnen alle redelijke extra kosten die daarbij worden gemaakt, in rekening worden gebracht aan Klant.</p>
        <p>&nbsp;</p>
        <p>3.3  Klant mag de Diensten niet gebruiken voor Toepassingen Met Verhoogd Risico, tenzij de Dienst uitdrukkelijk als geschikt voor dergelijke toepassingen is aangemerkt. Indien geen van de Diensten als geschikt is aangemerkt, kan Klant een verzoek indienen bij Hosting on Demand voor een maatwerkovereenkomst.</p>
        <p>&nbsp;</p>
        <p>3.4  Indien Klant voor het specifieke gebruik dat hij aan de Diensten geeft of beoogt te geven enige vergunning of andere toestemming van overheidsinstanties of derden nodig heeft, dient Klant zelf zorg te dragen voor het verkrijgen daarvan. Klant garandeert ten opzichte van Hosting on Demand dat hij alle vergunningen en/of toestemmingen bezit die noodzakelijk zijn voor het gebruik van de Diensten door Klant.</p>
        <h2>Artikel 4. Gedragsregels en notice/takedown</h2>
        <p>&nbsp;</p>
        <p>4.1  Het is Klant verboden om met gebruikmaking van de Diensten de Nederlandse of andere op Klant of Hosting on Demand van toepassing zijnde wet- of regelgeving te schenden of om inbreuk te maken op de rechten van anderen.</p>
        <p>&nbsp;</p>
        <p>4.2  Het is (of dit nu legaal is of niet) door Hosting on Demand verboden om met gebruikmaking van de Diensten Materialen aan te bieden of te verspreiden die:</p>
        <p>&nbsp;</p>
        <p>onmiskenbaar primair bedoeld zijn om anderen behulpzaam te zijn bij het schenden van de rechten van derden, zoals websites met (uitsluitend of voornamelijk) hacktools of uitleg over computercriminaliteit die kennelijk is bedoeld om de lezer in staat te stellen de omschreven criminele gedragingen te (doen) plegen en niet om zich daartegen te kunnen verdedigen;</p>
        <p>&nbsp;</p>
        <p>onmiskenbaar smadelijk, lasterlijk, beledigend, racistisch, discriminerend of haatzaaiend zijn;</p>
        <p>&nbsp;</p>
        <p>kinderpornografie of bestialiteitspornografie bevatten of er kennelijk op zijn gericht om anderen te helpen dergelijke materialen te vinden;</p>
        <p>&nbsp;</p>
        <p>een schending van de persoonlijke levenssfeer van derden opleveren, waaronder in ieder geval maar niet uitsluitend begrepen het zonder toestemming of noodzaak verspreiden van persoonsgegevens van derden of het herhaaldelijk lastigvallen van derden met door dezen ongewenste communicatie;</p>
        <p>&nbsp;</p>
        <p>hyperlinks, torrents of verwijzingen bevatten met (vindplaatsen van) materiaal dat onmiskenbaar inbreuk maakt op auteursrechten, naburige rechten of portretrechten;</p>
        <p>&nbsp;</p>
        <p>ongevraagde commerciële, charitatieve of ideeële communicatie bevat;</p>
        <p>&nbsp;</p>
        <p>kwaadaardige inhoud zoals virussen of spyware bevat.</p>
        <p>&nbsp;</p>
        <p>4.3  Het verspreiden van pornografische Materialen middels de Diensten is toegestaan voor zover dit geen overlast of andere overtreding van deze Algemene Voorwaarden oplevert.</p>
        <p>&nbsp;</p>
        <p>4.4  Klant onthoudt zich ervan overige klanten of internetgebruikers te hinderen of schade toe te brengen aan systemen of netwerken van Hosting on Demand of overige klanten. Het is Klant verboden processen of programma’s, al dan niet via de systemen van Hosting on Demand, op te starten waarvan Klant weet of redelijkerwijs kan vermoeden dat zulks Hosting on Demand, haar klanten of internetgebruikers hindert of schade toebrengt.</p>
        <p>&nbsp;</p>
        <p>4.5  Indien naar het oordeel van Hosting on Demand hinder, schade of een ander gevaar ontstaat voor het functioneren van de computersystemen of het netwerk van Hosting on Demand of derden en/of van de dienstverlening via internet, in het bijzonder door overmatig verzenden van e-mail of andere gegevens, denial-of-service-aanvallen, slecht beveiligde systemen of activiteiten van virussen, Trojans en vergelijkbare software, is Hosting on Demand gerechtigd alle maatregelen te nemen die zij redelijkerwijs nodig acht om dit gevaar af te wenden dan wel te voorkomen. Hosting on Demand mag de kosten die redelijkerwijs noodzakelijk gepaard gaan met deze maatregelen verhalen op Klant, indien Opdrachtgever een verwijt kan worden gemaakt omtrent de oorzaak.</p>
        <p>&nbsp;</p>
        <p>4.6  Wanneer Hosting on Demand een klacht ontvangt over overtreding van dit artikel door Klant, of zelf constateert dat hiervan sprake lijkt te zijn, zal Hosting on Demand Klant zo snel mogelijk op de hoogte stellen van de klacht of overtreding. Klant zal zo spoedig mogelijk een reactie geven, waarna Hosting on Demand zal besluiten hoe te handelen.</p>
        <p>&nbsp;</p>
        <p>4.7  Indien Hosting on Demand van oordeel is dat sprake is van een overtreding, zal zij de toegang tot het betreffende Materiaal blokkeren, echter zonder dit Materiaal definitief te verwijderen (tenzij dit technisch onmogelijk blijkt, in welk geval Hosting on Demand een backup zal maken). Hosting on Demand zal zich inspannen om daarbij geen overige Materialen te raken. Hosting on Demand zal Klant zo spoedig mogelijk op de hoogte stellen van genomen maatregelen.</p>
        <p>&nbsp;</p>
        <p>4.8  Hosting on Demand is te allen tijde gerechtigd om aangifte te doen van geconstateerde strafbare feiten.</p>
        <p>&nbsp;</p>
        <p>4.9  Hoewel Hosting on Demand ernaar streeft om zo redelijk, zorgvuldig en adequaat mogelijk op te treden na klachten over Klant, is Hosting on Demand nimmer gehouden tot vergoeding van schade als gevolg van maatregelen als bedoeld in dit artikel.</p>
        <p>&nbsp;</p>
        <p>4.10  Het is Klant toegestaan om de Diensten door te leveren (te “resellen”), echter uitsluitend in combinatie met of als onderdeel van de eigen producten of diensten van Klant en zonder de naam van Hosting on Demand als toeleverancier of onderaannemer kenbaar te maken. Klant dient Hosting on Demand te vrijwaren van alle claims door zijn afnemers. Hosting on Demand mag ook bij overtredingen van deze algemene voorwaarden door die afnemers onverkort optreden.</p>
        <h2>Artikel 5. Aanvraag van domeinnamen</h2>
        <p>5.1 Aanvraag, toekenning en eventueel gebruik van een domeinnaam zijn afhankelijk van en zijn onderworpen aan de geldende regels en procedures van de desbetreffende registrerende instanties, zoals de Stichting Internet Domeinregistratie Nederland bij .nl-domeinnamen. De desbetreffende instantie beslist over de toekenning van een domeinnaam. Hosting on Demand vervult bij de aanvraag slechts een bemiddelende rol en geeft geen garantie dat een aanvraag ook wordt gehonoreerd.</p>
        <p>&nbsp;</p>
        <p>5.2  Klant kan uitsluitend uit de bevestiging van Hosting on Demand, waarin vermeld wordt dat de gevraagde domeinnaam is geregistreerd, het feit van registratie vernemen. Een factuur voor registratiekosten is geen bevestiging van registratie.</p>
        <p>&nbsp;</p>
        <p>5.3  Klant vrijwaart en houdt Hosting on Demand schadeloos voor alle schade die verband houdt met (het gebruik van) een domeinnaam namens of door Klant. Hosting on Demand is niet aansprakelijk voor het verliezen door Klant van diens recht(en) op een domeinnaam of voor het feit dat de domeinnaam tussentijds door een derde wordt aangevraagd en/of verkregen, behoudens in geval van opzet of bewuste roekeloosheid van Klant.</p>
        <p>&nbsp;</p>
        <p>5.4  Klant dient zich te conformeren aan de regels die registrerende instanties stellen voor aanvraag, toekenning of gebruik van een domeinnaam. Hosting on Demand zal naar deze regels verwijzen tijdens de registratieprocedure.</p>
        <p>&nbsp;</p>
        <p>5.5  Hosting on Demand heeft het recht de domeinnaam ontoegankelijk of onbruikbaar te maken, dan wel op haar eigen naam te (doen) plaatsen wanneer Klant aantoonbaar in gebreke blijft bij de nakoming van de Overeenkomst, zulks echter uitsluitend voor de duur dat Klant in gebreke is en uitsluitend na verloop van een redelijke termijn tot nakoming gesteld in een schriftelijke ingebrekestelling.</p>
        <p>&nbsp;</p>
        <p>5.6  In geval van ontbinding van de Overeenkomst wegens wanprestatie van Klant is Hosting on Demand gerechtigd een domeinnaam van Klant op te zeggen met inachtneming van een opzegtermijn van twee maanden.</p>
        <h2>Artikel 6. Opslag- en datalimieten</h2>
        <p>6.1 Hosting on Demand kan een maximum stellen aan de hoeveelheid opslagruimte of dataverkeer per maand die Klant mag of feitelijk kan gebruiken in het kader van de Diensten.</p>
        <p>&nbsp;</p>
        <p>6.2  Mocht Klant de geldende limieten overschrijden, dan kan Hosting on Demand achteraf het meergebruik zonder waarschuwing in rekening brengen, volgens de tarieven uit de prijslijst.</p>
        <p>&nbsp;</p>
        <p>6.3  Geen aansprakelijkheid bestaat voor gevolgen van niet kunnen verzenden, ontvangen, opslaan of wijzigen van gegevens indien een afgesproken limiet voor opslagruimte of dataverkeer is overschreden.</p>
        <h2>Artikel 7. Rechten van intellectueel eigendom</h2>
        <p>&nbsp;</p>
        <p>7.1  Alle rechten van intellectueel eigendom op alle in het kader van de Overeenkomst door Hosting on Demand ontwikkelde of ter beschikking gestelde Materialen berusten uitsluitend bij Hosting on Demand of diens licentiegevers.</p>
        <p>&nbsp;</p>
        <p>7.2  Klant verkrijgt uitsluitend de gebruiksrechten en bevoegdheden die in deze Algemene Voorwaarden, de Overeenkomst of anderszins Schriftelijk expliciet worden toegekend en voor het overige zal Klant deze Materialen niet verveelvoudigen of openbaar maken. Voornoemde lijdt uitzondering indien onmiskenbaar per abuis is nagelaten aan Klant zo’n recht op uitdrukkelijke wijze te verstrekken. Afgifte van broncode van Materialen is echter te allen tijde alleen verplicht indien expliciet overeengekomen.</p>
        <p>&nbsp;</p>
        <p>7.3  Tenzij indien en voor zover Schriftelijk anders is overeengekomen, is het Klant niet toegestaan enige aanduiding omtrent auteursrechten, merken, handelsnamen of andere rechten van intellectueel eigendom uit deze Materialen te verwijderen of te wijzigen, daaronder begrepen aanduidingen omtrent het vertrouwelijk karakter en geheimhouding van de Materialen.</p>
        <p>&nbsp;</p>
        <p>7.4  Het is Hosting on Demand toegestaan technische maatregelen te nemen ter bescherming van diens Materialen. Indien Hosting on Demand door middel van technische bescherming deze Materialen heeft beveiligd, is het Klant niet toegestaan deze beveiliging te verwijderen of te ontwijken, behalve indien en voor zover de wet dwingend het tegendeel bepaald.</p>
        <h2>Artikel 8. Prijzen</h2>
        <p>8.1 Tenzij bij een bedrag uitdrukkelijk anders is vermeld, en tenzij het een consumentklant betreft, zijn alle door Hosting on Demand genoemde prijzen exclusief omzetbelasting en andere heffingen welke van overheidswege worden opgelegd.</p>
        <p>&nbsp;</p>
        <p>8.2  Indien een prijs is gebaseerd op door Klant verstrekte gegevens en deze gegevens blijken onjuist te zijn, dan heeft Hosting on Demand het recht de prijzen hierop aan te passen, ook nadat de Overeenkomst reeds tot stand is gekomen.</p>
        <p>&nbsp;</p>
        <p>8.3  Indien de Overeenkomst een Abonnement betreft, is Hosting on Demand gerechtigd de gehanteerde tarieven op elk moment te wijzigen.</p>
        <p>&nbsp;</p>
        <p>8.4  Voor prijswijziging gelden dezelfde voorwaarden en procedures als voor wijziging van de Diensten en van deze Algemene Voorwaarden.</p>
        <p>&nbsp;</p>
        <p>8.5  Op de door Hosting on Demand geleverde Diensten is jaarlijkse indexering van toepassing met het consumenten prijsindexcijfer (CPI) zoals gepubliceerd door het CBS. Indexering zal telkens per 1 januari berekend worden. De indexering zal nooit negatief worden toegepast.</p>
        <p>&nbsp;</p>
        <p>8.6 Indien Hosting on Demand namens de Contractant een overeenkomst heeft gesloten met een derde en die derde verhoogt haar tarieven, is Hosting on Demand gerechtigd die tariefsverhoging
         per direct door te voeren.</p>
        <p>&nbsp;</p>
        <h2>Artikel 9. Betalingsvoorwaarden</h2>
        <p>9.1  Hosting on Demand zal de door Klant verschuldigde bedragen aan Klant factureren. Hosting on Demand mag daarbij elektronische facturen uitreiken. Hosting on Demand heeft het recht om periodiek verschuldigde bedragen voorafgaand aan de levering van de Diensten in rekening te brengen.</p>
        <p>&nbsp;</p>
        <p>9.2  De betalingstermijn van een factuur is de termijn op de factuur staat aangegeven, tenzij Schriftelijk anders is overeengekomen.</p>
        <p>&nbsp;</p>
        <p>9.3  Indien Klant na 14 dagen na de betalingstermijn nog niet volledig heeft betaald, is hij automatisch in verzuim zonder dat hiervoor ingebrekestelling is vereist.</p>
        <p>&nbsp;</p>
        <p>9.4  Indien Klant in verzuim is, heeft dat de volgende consequenties:</p>
        <p>&nbsp;</p>
        <p>Over het openstaande bedrag is de wettelijke rente verschuldigd;</p>
        <p>&nbsp;</p>
        <p>Klant is 15% van de hoofdsom aan invorderingskosten verschuldigd, of 40 euro als dat bedrag meer is dan 15% van de hoofdsom;</p>
        <p>&nbsp;</p>
        <p>Klant is, naast het verschuldigde bedrag en de daarop verschenen rente, gehouden tot een volledige vergoeding van zowel buitengerechtelijke als gerechtelijke incassokosten, daaronder begrepen de kosten voor advocaten, juristen, deurwaarders en incassobureaus;</p>
        <p>&nbsp;</p>
        <p>de voor Klant gehoste websites en andere Materialen mogen zonder nadere waarschuwing ontoegankelijk worden gemaakt totdat de openstaande bedragen, rente en dergelijke zijn voldaan.</p>
        <p>&nbsp;</p>
        <p>9.5  Tenzij Klant een consument is, is beroep door Klant op opschorting, verrekening of aftrek niet toegestaan.</p>
        <p>&nbsp;</p>
        <p>9.6  In geval Klant enige verplichting uit de Overeenkomst niet nakomt, is Hosting on Demand zonder enige ingebrekestelling gerechtigd naast opschorting van Diensten geleverde goederen terug te nemen, onverminderd het recht van Hosting on Demand op vergoeding van schade, gederfde winst en interest.</p>
        <h2>Artikel 10. Aansprakelijkheid</h2>
        <p>&nbsp;</p>
        <p>10.1  Hosting on Demand is in het kader van de totstandkoming of uitvoering van de Overeenkomst niet aansprakelijk behoudens in de gevallen hieronder genoemd, en ten hoogste tot de daarbij vermelde limieten. Echter, in gevallen waarin de compensatieregeling in de SLA, die een boetebeding vormt, van toepassing is, treedt de uitkering daaronder in de plaats van vergoeding voor de werkelijke schade die door overtreding van de normen uit de SLA mocht ontstaan.</p>
        <p>&nbsp;</p>
        <p>10.2  De totale aansprakelijkheid van Hosting on Demand voor schade geleden door Klant als gevolg van een toerekenbare tekortkoming in de nakoming door Hosting on Demand van zijn verplichtingen onder de Overeenkomst, daaronder uitdrukkelijk ook begrepen iedere tekortkoming in de nakoming van een met Klant overeengekomen garantieverplichting, dan wel door een onrechtmatig handelen van Hosting on Demand, diens werknemers of door hem ingeschakelde derden, is per gebeurtenis dan wel een reeks van samenhangende gebeurtenissen beperkt tot een bedrag gelijk aan het totaal van de vergoedingen (exclusief BTW) die Klant onder de Overeenkomst heeft betaald tot het moment waarop de schade is ontstaan, of, indien de Overeenkomst een duur heeft van meer dan drie (3) maanden, een bedrag gelijk aan de vergoedingen die Klant in de laatste drie (3) maanden heeft betaald. In geen geval zal echter de totale vergoeding voor directe schade meer bedragen dan duizend (1.000) euro (exclusief BTW).</p>
        <p>&nbsp;</p>
        <p>10.3  Hosting on Demand is uitdrukkelijk niet aansprakelijk voor:</p>
        <p>&nbsp;</p>
        <p>a)  enige schade geleden door maatregelen die Hosting on Demand in goed vertrouwen heeft genomen, maar waarvan desalniettemin is gebleken dat deze ten onrechte zijn opgelegd;</p>
        <p>&nbsp;</p>
        <p>b)  schade uit onbeschikbaarheid van de Diensten, verloren gegevens en doorbreking van technische of organisatorische beveiligingsmaatregelen, en</p>
        <p>c) indirecte schade, gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie.</p>
        <p>&nbsp;</p>
        <p>10.4  De aansprakelijkheid van Hosting on Demand wegens toerekenbare tekortkoming in de nakoming van de Overeenkomst ontstaat slechts indien Klant Hosting on Demand onverwijld en deugdelijk Schriftelijk in gebreke stelt, stellende daarbij een redelijke termijn ter zuivering van de tekortkoming, en Hosting on Demand ook na die termijn toerekenbaar in de nakoming van zijn verplichtingen tekort blijft schieten. De ingebrekestelling dient een zo gedetailleerd mogelijke omschrijving van de tekortkoming te bevatten, zodat Hosting on Demand in staat is adequaat te reageren. De ingebrekestelling dient binnen 14 dagen na de ontdekking van de schade te zijn ontvangen door Hosting on Demand.</p>
        <p>&nbsp;</p>
        <p>10.5  De in dit artikel bedoelde uitsluitingen en beperkingen komen te vervallen indien en voor zover de schade het gevolg is van opzet of bewuste roekeloosheid van de bedrijfsleiding van Hosting on Demand.</p>
        <p>&nbsp;</p>
        <p>10.6  Klant is jegens Hosting on Demand aansprakelijk voor schade die door een aan hem toerekenbare fout of tekortkoming is ontstaan. Klant vrijwaart Hosting on Demand tegen aanspraken betreffende het bij de gebruikmaking van de Diensten door of met toestemming van Klant niet naleven van de gedragsregels in artikel . Deze vrijwaring geldt ook ten aanzien van personen die weliswaar geen werknemers van Klant zijn, maar de Diensten desalniettemin onder de verantwoordelijkheid of met toestemming van Klant hebben gebruikt.</p>
        <h2>Artikel 11. Overmacht</h2>
        <p>&nbsp;</p>
        <p>11.1  Geen van de partijen kan tot nakoming van enige verplichting worden gehouden indien een omstandigheid die buiten de macht van partijen ligt en die bij het sluiten van de Overeenkomst niet reeds kon of had moeten worden voorzien, iedere redelijke mogelijkheid tot nakoming tenietdoet.</p>
        <p>&nbsp;</p>
        <p>11.2  Onder overmacht worden mede (maar niet uitsluitend) verstaan: storingen van publieke infrastructuur die normaliter voor Hosting on Demand beschikbaar is, en waar de levering van de Diensten van afhankelijk is, maar waarover Hosting on Demand geen feitelijke macht of contractuele nakomingsverbintenis kan uitoefenen, zoals de werking van de registers van IANA, RIPE of SIDN, en alle netwerken in het internet waar Hosting on Demand geen contract mee heeft gesloten; storingen in infrastructuur en/of Diensten van Hosting on Demand die zijn veroorzaakt door computercriminaliteit, bijvoorbeeld (D)DOS-aanvallen of al dan niet succesvolle pogingen om de netwerkbeveiliging of systeembeveiliging te omzeilen; tekortkomingen van leveranciers van Hosting on Demand, die Hosting on Demand niet kon voorzien en waar Hosting on Demand diens leverancier niet voor aansprakelijk kan stellen, bijvoorbeeld doordat bij de betreffende leverancier (eveneens) sprake was van overmacht; Gebrekkigheid van zaken, apparatuur, programmatuur of ander bronmateriaal waarvan Klant het gebruik heeft voorgeschreven; Onbeschikbaarheid van personeelsleden (door ziekte of anderszins); overheidsmaatregelen; algemene vervoersproblemen; stakingen; oorlogen; terroristische aanslagen en binnenlandse onlusten.</p>
        <p>11.3 Indien een overmachtsituatie langer dan drie maanden duurt, heeft elk der partijen het recht om de overeenkomst Schriftelijk te ontbinden. Hetgeen reeds op grond van de overeenkomst gepresteerd is, wordt in dat geval naar verhouding afgerekend, zonder dat partijen elkaar overigens iets verschuldigd zullen zijn.</p>
        <h2>Artikel 12. Geheimhouding</h2>
        <p>&nbsp;</p>
        <p>12.1  Partijen zullen informatie die zij voor, tijdens of na de uitvoering van de Overeenkomst aan elkaar verstrekken, vertrouwelijk behandelen wanneer deze informatie is gemarkeerd als vertrouwelijk of wanneer de ontvangende partij weet of redelijkerwijs moet vermoeden dat de informatie als vertrouwelijk bedoeld was. Partijen leggen deze verplichting tevens op aan hun werknemers alsmede aan door hen ingeschakelde derden ter uitvoering van de Overeenkomst.</p>
        <p>&nbsp;</p>
        <p>12.2  Hosting on Demand zal geen kennis nemen van gegevens die Klant opslaat en/of verspreidt via de systemen van Hosting on Demand, tenzij dit noodzakelijk is voor een goede uitvoering van de Overeenkomst of Hosting on Demand daartoe verplicht is krachtens een wettelijke bepaling of gerechtelijk bevel. In dat geval zal Hosting on Demand zich inspannen de kennisname van de gegevens zo veel mogelijk te beperken, voor zover dit binnen zijn macht ligt.</p>
        <p>&nbsp;</p>
        <p>12.3  De verplichting tot geheimhouding blijft ook bestaan na beëindiging van de Overeenkomst om welke reden dan ook, en wel voor zolang als de verstrekkende partij redelijkerwijs aanspraak kan maken op het vertrouwelijke karakter van de informatie.</p>
        <h2>Artikel 13. Duur en opzegging</h2>
        <p>&nbsp;</p>
        <p>13.1  De duur van de Overeenkomst is die tijdsspanne die nodig is om de Diensten te leveren. Indien de Overeenkomst een Abonnement is, is deze aangegaan voor een termijn van een jaar.</p>
        <p>&nbsp;</p>
        <p>13.2  Indien een vaste looptijd voor het Abonnement is afgesproken, mag geen van de partijen de Overeenkomst eenzijdig beëindigen voordat de looptijd is verstreken, behalve als er een bijzondere opzeggingsgrond geldt, zoals hieronder nader is omschreven.</p>
        <p>&nbsp;</p>
        <p>13.3  Bij gebreke van tijdige opzegging wordt een Abonnement na het verstrijken van de eerste contractstermijn omgezet naar een voor onbepaalde tijd. Hierbij kan op ieder moment met een opzegtermijn van één maand worden opgezegd.</p>
        <p>&nbsp;</p>
        <p>13.4  Hosting on Demand mag de Overeenkomst per direct Schriftelijk opschorten of opzeggen indien ten minste één van de volgende bijzondere gronden van toepassing is:</p>
        <p>&nbsp;</p>
        <p>a)  Klant is in verzuim met betrekking tot een wezenlijke verplichting;</p>
        <p>&nbsp;</p>
        <p>b)  Het faillissement van Klant is aangevraagd;</p>
        <p>&nbsp;</p>
        <p>c)  Klant heeft surseance van betaling aangevraagd;</p>
        <p>&nbsp;</p>
        <p>d)  De activiteiten van Klant worden beëindigd of geliquideerd.</p>
        <p>&nbsp;</p>
        <p>13.5  Indien Hosting on Demand de nakoming van de verplichtingen opschort, behoudt zij haar aanspraken uit de wet en de Overeenkomst, met inbegrip van de aanspraak op betaling voor de Diensten die zijn opgeschort.</p>
        <p>&nbsp;</p>
        <p>13.6  Indien de Overeenkomst wordt beëindigd of ontbonden, zijn de vorderingen van Hosting on Demand op Klant onmiddellijk opeisbaar. In geval van ontbinding van de Overeenkomst blijven reeds gefactureerde bedragen voor verrichte prestaties verschuldigd, zonder enige verplichting tot ongedaanmaking. In het geval van ontbinding door Klant mag Klant uitsluitend dat deel van de overeenkomst ontbinden dat nog niet door Hosting on Demand is uitgevoerd. Indien de ontbinding aan Klant toerekenbaar is, heeft Hosting on Demand recht op vergoeding van de schade die daardoor direct en indirect ontstaat.</p>
        <p>13.7 Het recht tot opschorting in bovengenoemde gevallen geldt voor alle met Klant gesloten Overeenkomsten gelijktijdig, ook als Klant slechts in verzuim is met betrekking tot een Overeenkomst, en onverminderd het recht van Hosting on Demand op vergoeding van schade, gederfde winst en rente.</p>
        <h2>Artikel 14. Procedure na beëindiging</h2>
        <p>&nbsp;</p>
        <p>14.1  Na beëindiging van de Overeenkomst, als gevolg van opzegging dan wel ontbinding, is Hosting on Demand gerechtigd om per direct alle opgeslagen gegevens te wissen of ontoegankelijk te maken en alle Accounts van Klant op te heffen.</p>
        <p>&nbsp;</p>
        <p>14.2  Het wissen van voor Klant opgeslagen gegevens gebeurt standaard zonder speciale voorzorgen om het wissen onomkeerbaar te maken, maar kan op verzoek tegen bijbetaling met behulp van een softwarematige datashredder gebeuren.</p>
        <h2>Artikel 15. Rangorde en wijziging voorwaarden</h2>
        <p>&nbsp;</p>
        <p>15.1  Hosting on Demand behoudt zich het recht voor de Diensten en deze Algemene Voorwaarden te wijzigen of aan te vullen. Wijzigingen gelden ook ten aanzien van reeds gesloten overeenkomsten met inachtneming van een termijn van 30 dagen na bekendmaking van de wijziging.</p>
        <p>&nbsp;</p>
        <p>15.2  Wijzigingen worden bekendgemaakt op de Hosting on Demand Website, of per e- mail aan Klant, of een ander kanaal waarvan Hosting on Demand kan bewijzen dat de bekendmaking is aangekomen bij Klant. Niet-inhoudelijke wijzigingen van ondergeschikt belang kunnen te allen tijde worden doorgevoerd en behoeven geen berichtgeving.</p>
        <p>&nbsp;</p>
        <p>15.3  Indien Klant een wijziging niet wil accepteren, dient Klant dit binnen twee weken na bekendmaking Schriftelijk gemotiveerd mede te delen aan Hosting on Demand. Hosting on Demand kan daarop de wijziging heroverwegen. Indien Hosting on Demand daarop de wijziging niet intrekt, kan Klant tot de datum waarop de nieuwe voorwaarden van kracht worden, de overeenkomst beëindigen tegen deze datum.</p>
        <p>&nbsp;</p>
        <p>15.4  Bepalingen die op specifieke Diensten betrekking hebben, gaan als zij van toepassing zijn, voor boven algemene bepalingen die op alle diensten betrekking hebben. Nadere afspraken tussen Hosting on Demand en Klant prevaleren alleen dan boven deze Algemene Voorwaarden indien deze Schriftelijk zijn en indien dat uitdrukkelijk is bepaald, of onmiskenbaar de bedoeling van beide partijen was.</p>
        <h2>Artikel 16. Overige bepalingen</h2>
        <p>&nbsp;</p>
        <p>16.1  Op de Overeenkomst is Nederlands recht van toepassing.</p>
        <p>&nbsp;</p>
        <p>16.2  Voor zover door de regels van dwingend recht niet anders wordt voorgeschreven, zullen alle geschillen die mochten ontstaan naar aanleiding van de Overeenkomst worden voorgelegd aan de bevoegde Nederlandse rechter voor het arrondissement waarin Hosting on Demand gevestigd is.</p>
        <p>&nbsp;</p>
        <p>16.3  Indien enige bepaling uit de Overeenkomst nietig blijkt te zijn, tast dit niet de geldigheid van de gehele overeenkomst aan. Partijen zullen in dat geval ter vervanging (een) nieuwe bepaling(en) vaststellen, waarmee zoveel als rechtens mogelijk is aan de bedoeling van de oorspronkelijke Overeenkomst en Algemene Voorwaarden gestalte wordt gegeven.</p>
        <p>&nbsp;</p>
        <p>16.4  Informatie en mededelingen, inclusief prijsaanduidingen, op de Hosting on Demand Website zijn onder voorbehoud van programmeer- en typefouten. In geval van enige inconsistentie tussen de Website en de Overeenkomst prevaleert de Overeenkomst.</p>
        <p>&nbsp;</p>
        <p>16.5  De logfiles en overige al dan niet elektronische administratie van Hosting on Demand vormen volledig bewijs van stellingen van Hosting on Demand en de door Hosting on Demand ontvangen of opgeslagen versie van enige (elektronische) communicatie geldt als authentiek, behoudens tegenbewijs te leveren door Klant.</p>
        <p>&nbsp;</p>
        <p>16.6  Partijen stellen elkaar steeds onverwijld Schriftelijk op de hoogte van enige wijzigingen in naam, postadres, e-mailadres, telefoonnummer en desgevraagd bank- of gironummer.</p>
        <p>&nbsp;</p>
        <p>16.7  Iedere partij is slechts gerechtigd haar rechten en verplichtingen uit de Overeenkomst over te dragen aan een derde met voorafgaande Schriftelijke toestemming van de andere partij. Deze toestemming is echter niet nodig in geval van bedrijfsovername of overname van de meerderheid van de aandelen van de betreffende partij.</p>
        <h2>Artikel 17. Definities van gebruikte termen</h2>
        <p>In deze voorwaarden wordt verstaan onder:</p>
        <p>Hosting on Demand: het bedrijf Hosting on Demand B.V., gevestigd te Twello en ingeschreven bij de Kamer van Koophandel onder dossiernummer 08192859.</p>
        <p>Hosting on Demand Website:de website van Hosting on Demand, bereikbaar via het domein https://hostingondemand.nl.</p>
        <p>Abonnement: de Overeenkomst waarbij één of meer der partijen zich verbindt om gedurende een zekere tijdsperiode continu of steeds opnieuw prestaties te verrichten (bijv. een hostingcontract van 12 maanden).</p>
        <p>Account:het toegangsrecht tot een gebruikersinterface waarmee Klant (bepaalde aspecten van) de Diensten kan beheren en configureren, alsmede de configuratie(s) en de voor Klant opgeslagen bestanden zelf.</p>
        <p>Algemene Voorwaarden:de bepalingen uit het onderhavige document.</p>
        <p>Klant: de natuurlijke persoon of rechtspersoon met wie Hosting on Demand een Overeenkomst heeft gesloten. Tevens wordt bedoeld degene die met Hosting on Demand daarover in onderhandeling treedt of is, alsmede diens vertegen-woordiger(s), gemachtigde(n), rechtsverkrijgende(n) en erfgenamen.</p>
        <p>Diensten: de producten en/of diensten die Hosting on Demand aan Klant zal leveren krachtens een Overeenkomst.</p>
        <p>Materialen: alle werken, zoals websites en (web)applicaties, software, huisstijlen, logo’s, folders, brochures, leaflets, belettering, advertenties, marketing- en/of communicatieplannen, concepten, afbeeldingen, teksten, schetsen, documentatie, adviezen, rapporten en andere voortbrengselen van de geest, alsmede voorbereidend materiaal daarvan en (al dan niet gecodeerde) bestanden of gegevensdragers waarop de Materialen zich bevinden.</p>
        <p>Overeenkomst: iedere overeenkomst tussen Hosting on Demand en Klant op grond waarvan Hosting on Demand Diensten levert aan Klant.</p>
        <p>Schriftelijk: naast papieren geschriften ook e-mail en communicatie per fax, mits de identiteit van de afzender en de integriteit van het bericht voldoende vaststaat.</p>
        <p>Toepassingen Met Verhoogd Risico: toepassingen waarbij een fout in de Diensten kan leiden tot dood of ernstig letsel, ernstige milieuschade of verlies van (persoons)gegevens met zeer hoge gevolgschade. Voorbeelden van Toepassingen met verhoogd risico zijn: vervoerssystemen waarbij een fout tot gevolg kan hebben dat treinen ontsporen of vliegtuigen verongelukken; medische systemen waarbij een fout tot gevolg kan hebben dat een patiënt geen of een verkeerde behandeling kan krijgen; systemen waar een substantieel deel van de bevolking afhankelijk van is voor de toebedeling van cruciale overheidsdiensten, zoals DigiD; systemen waarin (veel) medische gegevens of andere bijzondere gegevens in de zin van de Wet bescherming persoonsgegevens, of anderszins zeer gevoelige gegevens zijn opgeslagen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'TermsAndConditions',
  components: {
    Breadcrumbs,
  },
}
</script>
